import Proxy from "./Proxy";

class PageProxy extends Proxy {
  /**
   * The constructor for the LocationProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("pages", parameters);
  }

  /**
   * @returns {Promise}
   */
  notifications() {
    return this.submit("get", `${this.endpoint}/notifications`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  hotel(id) {
    return this.submit("get", `${this.endpoint}/hotel/${id}`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  hotelBook(id) {
    return this.submit("get", `${this.endpoint}/hotel/${id}/book`);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  hotels(params) {
    this.parameters = Object.assign(
      {
        lang: localStorage.getItem("locale"),
      },
      params
    );
    return this.submit("get", `${this.endpoint}/hotels`);
  }

  /**
   * @returns {Promise}
   */
  transfersBook() {
    return this.submit("get", `${this.endpoint}/transfers/book`);
  }

  /**
   *
   * @param params
   * @returns {Promise}
   */
  transfers(params) {
    this.parameters = Object.assign(
      {
        lang: localStorage.getItem("locale"),
      },
      params
    );
    return this.submit("get", `${this.endpoint}/transfers`);
  }

  /**
   * @returns {Promise}
   */
  activitiesBook() {
    return this.submit("get", `${this.endpoint}/activities/book`);
  }

  /**
   *
   * @param params
   * @returns {Promise}
   */
  activities(params) {
    this.parameters = Object.assign(
      {
        lang: localStorage.getItem("locale"),
      },
      params
    );
    return this.submit("get", `${this.endpoint}/activities`);
  }

  /**
   *
   * @param params
   * @returns {Promise}
   */
  filters(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/filters`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  reservation(id) {
    return this.submit("get", `${this.endpoint}/reservations/${id}`);
  }

  /**
   * @param reservationId
   * @param reservationItemId
   * @returns {Promise}
   */
  reservationItem(reservationId, reservationItemId) {
    return this.submit(
      "get",
      `${this.endpoint}/reservations/${reservationId}/${reservationItemId}`
    );
  }
}

export default PageProxy;
