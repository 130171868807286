/* ============
 * Mutation types for the hotels module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const RESET = "RESET";
export const DO_SEARCH = "DO_SEARCH";
export const DO_FILTER = "DO_FILTER";
export const SET_ERROR = "SET_ERROR";
export const SET_RESULTS_TOTAL = "SET_RESULTS_TOTAL";
export const SET_RESULTS_COUNT = "SET_RESULTS_COUNT";
export const SET_FILTERS_VALUES = "SET_FILTERS_VALUES";
export const SET_FILTERS_OPTIONS = "SET_FILTERS_OPTIONS";

export default {
  RESET,
  DO_SEARCH,
  DO_FILTER,
  SET_ERROR,
  SET_RESULTS_TOTAL,
  SET_RESULTS_COUNT,
  SET_FILTERS_VALUES,
  SET_FILTERS_OPTIONS,
};
