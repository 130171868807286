import PageProxy from "@/proxies/PageProxy";
import AdminProxy from "@/proxies/AdminProxy";

export const airport = ({}, { airportId, onSuccess, onError }) => {
  new AdminProxy().airport(airportId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const airportUpdate = (
  {},
  { airportId, params, onSuccess, onError }
) => {
  new AdminProxy().airportUpdate(airportId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const airports = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().airports(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const area = ({}, { areaId, onSuccess, onError }) => {
  new AdminProxy().area(areaId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areaCreate = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().areaCreate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areaUpdate = ({}, { areaId, params, onSuccess, onError }) => {
  new AdminProxy().areaUpdate(areaId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areaDelete = ({}, { areaId, onSuccess, onError }) => {
  new AdminProxy().areaDelete(areaId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areaItemCreate = ({}, { areaId, params, onSuccess, onError }) => {
  new AdminProxy().areaItemCreate(areaId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areaItemDelete = (
  {},
  { areaId, areaItemId, onSuccess, onError }
) => {
  new AdminProxy().areaItemDelete(areaId, areaItemId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const areas = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().areas(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const city = ({}, { cityId, onSuccess, onError }) => {
  new AdminProxy().city(cityId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const cityUpdate = ({}, { cityId, params, onSuccess, onError }) => {
  new AdminProxy().cityUpdate(cityId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const cities = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().cities(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const destination = ({}, { destinationId, onSuccess, onError }) => {
  new AdminProxy().destination(destinationId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const destinationUpdate = (
  {},
  { destinationId, params, onSuccess, onError }
) => {
  new AdminProxy().destinationUpdate(destinationId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const destinations = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().destinations(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const currencies = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().currencies(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const currencyUpdate = (
  {},
  { currencyId, params, onSuccess, onError }
) => {
  new AdminProxy().currencyUpdate(currencyId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const meal = ({}, { mealId, onSuccess, onError }) => {
  new AdminProxy().meal(mealId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const mealUpdate = ({}, { mealId, params, onSuccess, onError }) => {
  new AdminProxy().mealUpdate(mealId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const meals = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().meals(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const mealsMap = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().mealsMap(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const searchesHotels = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().searchesHotels(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const searchesTransfers = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().searchesTransfers(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const searchesActivities = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().searchesActivities(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const filters = ({}, { params, onSuccess, onError }) => {
  new PageProxy().filters(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const overview = ({}, { onSuccess, onError }) => {
  new AdminProxy().overview().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const confirmations = ({}, { onSuccess, onError }) => {
  new AdminProxy().confirmations().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const commission = ({}, { commissionId, onSuccess, onError }) => {
  new AdminProxy().commission(commissionId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const commissionUpdate = (
  {},
  { commissionId, params, onSuccess, onError }
) => {
  new AdminProxy().commissionUpdate(commissionId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const commissionItems = (
  {},
  { commissionId, params, onSuccess, onError }
) => {
  new AdminProxy().commissionItems(commissionId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const commissions = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().commissions(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const agencyStatement = ({}, { statementId, onSuccess, onError }) => {
  new AdminProxy().statement(statementId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const agencyStatements = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().statements(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const agencyStatementOptions = ({}, { onSuccess, onError }) => {
  new AdminProxy().statementOptions().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const agencyStatementCreate = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().statementCreate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const agencyStatementUpdate = (
  {},
  { statementId, params, onSuccess, onError }
) => {
  new AdminProxy().statementUpdate(statementId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatement = ({}, { statementId, onSuccess, onError }) => {
  new AdminProxy().supplierStatement(statementId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatements = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().supplierStatements(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementFiles = (
  {},
  { statementId, onSuccess, onError }
) => {
  new AdminProxy().supplierStatementFiles(statementId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementFileDelete = (
  {},
  { fileName, statementId, params, onSuccess, onError }
) => {
  new AdminProxy()
    .supplierStatementFileDelete(fileName, statementId, params)
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Callback
        onError(error);
      }
    );
};

export const supplierStatementReservations = (
  {},
  { params, onSuccess, onError }
) => {
  new AdminProxy().supplierStatementReservations(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementCreate = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().supplierStatementCreate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementUpdate = (
  {},
  { statementId, params, onSuccess, onError }
) => {
  new AdminProxy().supplierStatementUpdate(statementId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementDelete = (
  {},
  { statementId, onSuccess, onError }
) => {
  new AdminProxy().supplierStatementDelete(statementId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementPaymentCreate = (
  {},
  { statementId, params, onSuccess, onError }
) => {
  new AdminProxy().supplierStatementPaymentCreate(statementId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const supplierStatementPaymentDelete = (
  {},
  { paymentId, statementId, params, onSuccess, onError }
) => {
  new AdminProxy()
    .supplierStatementPaymentDelete(paymentId, statementId, params)
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Callback
        onError(error);
      }
    );
};

export const requestsHotels = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().requestsHotels(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const requestsTransfers = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().requestsTransfers(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const requestsActivities = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().requestsActivities(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const requestsPayments = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().requestsPayments(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateAssignee = (
  {},
  { bookingId, assigneeId, onSuccess, onError }
) => {
  new AdminProxy().updateAssignee(bookingId, assigneeId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateMessagePriority = (
  {},
  { messageId, priority, onSuccess, onError }
) => {
  new AdminProxy().updateMessagePriority(messageId, priority).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateMessageStatus = (
  {},
  { messageId, status, onSuccess, onError }
) => {
  new AdminProxy().updateMessageStatus(messageId, status).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateMessageAlert = (
  {},
  { messageId, alert, onSuccess, onError }
) => {
  new AdminProxy().updateMessageAlert(messageId, alert).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateMessageUnseen = ({}, { messageId, onSuccess, onError }) => {
  new AdminProxy().updateMessageUnseen(messageId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateMessageSeen = ({}, { messageId, onSuccess, onError }) => {
  new AdminProxy().updateMessageSeen(messageId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const whitelists = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().whitelists(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const whitelistCreate = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().whitelistCreate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};
export const whitelistDelete = ({}, { supplierId, onSuccess, onError }) => {
  new AdminProxy().whitelistDelete(supplierId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const blacklists = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().blacklists(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export const blacklistCreate = ({}, { params, onSuccess, onError }) => {
  new AdminProxy().blacklistCreate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};
export const blacklistDelete = (
  {},
  { hotelBlacklistId, onSuccess, onError }
) => {
  new AdminProxy().blacklistDelete(hotelBlacklistId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      console.log(error);
      // Error Callback
      onError(error);
    }
  );
};

export default {
  airport,
  airportUpdate,
  airports,
  area,
  areaCreate,
  areaUpdate,
  areaDelete,
  areaItemCreate,
  areaItemDelete,
  areas,
  city,
  cityUpdate,
  cities,
  destination,
  destinationUpdate,
  destinations,
  meal,
  mealUpdate,
  meals,
  mealsMap,
  currencies,
  currencyUpdate,
  filters,
  overview,
  confirmations,
  commission,
  commissionItems,
  commissionUpdate,
  commissions,
  agencyStatement,
  agencyStatements,
  agencyStatementOptions,
  agencyStatementCreate,
  agencyStatementUpdate,
  supplierStatement,
  supplierStatements,
  supplierStatementCreate,
  supplierStatementUpdate,
  supplierStatementDelete,
  supplierStatementFiles,
  supplierStatementFileDelete,
  supplierStatementReservations,
  supplierStatementPaymentCreate,
  supplierStatementPaymentDelete,
  searchesHotels,
  searchesTransfers,
  searchesActivities,
  requestsHotels,
  requestsTransfers,
  requestsActivities,
  requestsPayments,
  updateAssignee,
  updateMessagePriority,
  updateMessageStatus,
  updateMessageAlert,
  updateMessageUnseen,
  updateMessageSeen,
  whitelists,
  whitelistCreate,
  whitelistDelete,
  blacklists,
  blacklistCreate,
  blacklistDelete,
};
