/* ============
 * Mutations for the auth module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import { CHECK, LOGIN, LOGOUT } from "./mutation-types";

export default {
  [CHECK](state) {
    state.authenticated = !!localStorage.getItem("hspToken");
  },

  [LOGIN](state, token) {
    state.authenticated = true;
    localStorage.setItem("hspToken", token);
  },

  [LOGOUT](state) {
    state.authenticated = false;
    localStorage.removeItem("hspToken");
  },
};
