/* ============
 * Mutations for the activities module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from "./mutation-types";

export default {
  [types.RESET](state) {
    state.error = null;
    state.doSearch = false;
    state.doFilter = false;
    state.resultsCount = 0;
    state.resultsTotal = 0;
    state.filtersValues = {};
  },
  [types.SET_ERROR](state, value) {
    state.error = value;
    // Reset
    state.doSearch = false;
    state.doFilter = false;
    state.resultsCount = 0;
    state.resultsTotal = 0;
    state.filtersValues = {};
  },
  [types.DO_SEARCH](state, value) {
    state.doSearch = value;
    // Reset
    state.error = null;
    state.doFilter = false;
    state.resultsCount = 0;
    state.resultsTotal = 0;
    state.filtersValues = {};
  },
  [types.DO_FILTER](state, value) {
    state.doFilter = value;
    // Reset
    state.error = null;
  },
  [types.SET_FILTERS_VALUES](state, value) {
    state.filtersValues = value;
  },
  [types.SET_RESULTS_COUNT](state, value) {
    state.resultsCount = value;
  },
  [types.SET_RESULTS_TOTAL](state, value) {
    state.resultsTotal = value;
  },
};
