/* ============
 * State of the messages module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  new: 0,
  alert: 0,
};
