/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  error: null,
  doSearch: false,
  doFilter: false,
  resultsTotal: 0,
  resultsCount: 0,
  filtersValues: {},
};
