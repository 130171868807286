/* ============
 * Dutch Language File
 * ============
 *
 * An example language file.
 */

import el from "./el";
import * as home from "./home.json";
import * as auth from "./auth.json";
import * as hotel from "./hotel.json";
import * as facts from "./facts.json";
import * as error from "./error.json";
import * as common from "./common.json";
import * as message from "./message.json";
import * as transfer from "./transfer.json";
import * as admin from "./admin.json";
import * as activity from "./activity.json";
import * as country from "./country.json";

export default {
  el,
  home,
  auth,
  hotel,
  facts,
  error,
  common,
  message,
  transfer,
  admin,
  activity,
  country,
};
