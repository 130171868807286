import Proxy from "./Proxy";

class AdminProxy extends Proxy {
  /**
   * The constructor for the AdminProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("admin", parameters);
  }

  overview() {
    return this.submit("get", `${this.endpoint}/overview`);
  }

  confirmations() {
    return this.submit("get", `${this.endpoint}/confirmations`);
  }

  airport(airportId) {
    return this.submit("get", `${this.endpoint}/airports/${airportId}`);
  }

  airportUpdate(airportId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/airports/${airportId}`,
      params
    );
  }

  airports(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/airports`);
  }

  area(areaId) {
    return this.submit("get", `${this.endpoint}/areas/${areaId}`);
  }

  areas(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/areas`);
  }

  areaCreate(params) {
    return this.submit("post", `${this.endpoint}/areas`, params);
  }

  areaUpdate(areaId, params) {
    return this.submit("post", `${this.endpoint}/areas/${areaId}`, params);
  }

  areaDelete(areaId) {
    return this.submit("delete", `${this.endpoint}/areas/${areaId}`);
  }

  areaItemCreate(areaId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/areas/${areaId}/items`,
      params
    );
  }

  areaItemDelete(areaId, areaItemId) {
    return this.submit(
      "delete",
      `${this.endpoint}/areas/${areaId}/items/${areaItemId}`
    );
  }

  city(cityId) {
    return this.submit("get", `${this.endpoint}/cities/${cityId}`);
  }

  cityUpdate(cityId, params) {
    return this.submit("post", `${this.endpoint}/cities/${cityId}`, params);
  }

  cities(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/cities`);
  }

  destination(destinationId) {
    return this.submit("get", `${this.endpoint}/destinations/${destinationId}`);
  }

  destinationUpdate(destinationId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/destinations/${destinationId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  destinations(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/destinations`);
  }

  /**
   * @returns {Promise}
   */
  currencies(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/currencies`);
  }

  /**
   * @returns {Promise}
   */
  currencyUpdate(currencyId, params) {
    this.parameters = params;

    return this.submit(
      "post",
      `${this.endpoint}/currencies/${currencyId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  meal(mealId) {
    return this.submit("get", `${this.endpoint}/meals/${mealId}`);
  }

  mealUpdate(mealId, params) {
    return this.submit("post", `${this.endpoint}/meals/${mealId}`, params);
  }

  /**
   * @returns {Promise}
   */
  meals(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/meals`);
  }

  /**
   * @returns {Promise}
   */
  mealsMap(params) {
    return this.submit("post", `${this.endpoint}/meals/map`, params);
  }

  /**
   * @returns {Promise}
   */
  commission(commissionId) {
    return this.submit("get", `${this.endpoint}/commissions/${commissionId}`);
  }

  /**
   * @returns {Promise}
   */
  commissionUpdate(commissionId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/commissions/${commissionId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  commissionItems(commissionId, params) {
    this.parameters = params;
    return this.submit(
      "get",
      `${this.endpoint}/commissions/${commissionId}/items`
    );
  }

  /**
   * @returns {Promise}
   */
  commissions(params) {
    this.parameters = params;
    return this.submit("get", `${this.endpoint}/commissions`);
  }

  /**
   * @returns {Promise}
   */
  statement(statementId) {
    return this.submit("get", `${this.endpoint}/statements/${statementId}`);
  }

  /**
   * @returns {Promise}
   */
  statements(params) {
    this.parameters = params;
    return this.submit("get", `${this.endpoint}/statements`);
  }

  /**
   * @returns {Promise}
   */
  statementOptions() {
    return this.submit("get", `${this.endpoint}/statements/options`);
  }

  /**
   * @returns {Promise}
   */
  statementCreate(params) {
    return this.submit("post", `${this.endpoint}/statements`, params);
  }

  /**
   * @returns {Promise}
   */
  statementUpdate(commissionId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/statements/${commissionId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatement(statementId) {
    return this.submit(
      "get",
      `${this.endpoint}/supplierStatements/${statementId}`
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatements(params) {
    this.parameters = params;
    return this.submit("get", `${this.endpoint}/supplierStatements`);
  }

  /**
   * @returns {Promise}
   */
  supplierStatementCreate(params) {
    return this.submit("post", `${this.endpoint}/supplierStatements`, params);
  }

  /**
   * @returns {Promise}
   */
  supplierStatementUpdate(statementId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/supplierStatements/${statementId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementDelete(statementId) {
    return this.submit(
      "delete",
      `${this.endpoint}/supplierStatements/${statementId}`
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementFiles(statementId) {
    return this.submit(
      "get",
      `${this.endpoint}/supplierStatements/${statementId}/files`
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementFileDelete(fileName, statementId, params) {
    return this.submit(
      "delete",
      `${this.endpoint}/supplierStatements/${statementId}/files/${fileName}/delete`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementReservations(params) {
    return this.submit(
      "post",
      `${this.endpoint}/supplierStatements/reservations`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementPaymentCreate(statementId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/supplierStatements/${statementId}/payments`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  supplierStatementPaymentDelete(paymentId, statementId, params) {
    return this.submit(
      "delete",
      `${this.endpoint}/supplierStatements/${statementId}/payments/${paymentId}`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  searchesHotels(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/searches/hotels`);
  }

  /**
   * @returns {Promise}
   */
  searchesTransfers(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/searches/transfers`);
  }

  /**
   * @returns {Promise}
   */
  searchesActivities(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/searches/activities`);
  }

  /**
   * @returns {Promise}
   */
  requestsHotels(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/requests/hotels`);
  }

  /**
   * @returns {Promise}
   */
  requestsTransfers(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/requests/transfers`);
  }

  /**
   * @returns {Promise}
   */
  requestsActivities(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/requests/activities`);
  }

  /**
   * @returns {Promise}
   */
  requestsPayments(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/requests/payments`);
  }

  /**
   * @returns {Promise}
   */
  updateAssignee(bookingId, assigneeId) {
    return this.submit(
      "post",
      `${this.endpoint}/bookings/${bookingId}/updateAssignee`,
      { assigneeId }
    );
  }

  /**
   * @returns {Promise}
   */
  updateMessagePriority(messageId, priority) {
    return this.submit(
      "post",
      `${this.endpoint}/messages/${messageId}/updatePriority`,
      { priority }
    );
  }

  /**
   * @returns {Promise}
   */
  updateMessageStatus(messageId, status) {
    return this.submit(
      "post",
      `${this.endpoint}/messages/${messageId}/updateStatus`,
      { status }
    );
  }

  /**
   * @returns {Promise}
   */
  updateMessageAlert(messageId, alert) {
    return this.submit(
      "post",
      `${this.endpoint}/messages/${messageId}/updateAlert`,
      { alert }
    );
  }

  /**
   * @returns {Promise}
   */
  updateMessageUnseen(messageId) {
    return this.submit(
      "post",
      `${this.endpoint}/messages/${messageId}/updateUnseen`
    );
  }

  /**
   * @returns {Promise}
   */
  updateMessageSeen(messageId) {
    return this.submit(
      "post",
      `${this.endpoint}/messages/${messageId}/updateSeen`
    );
  }

  whitelists(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/hotels/whitelists`);
  }

  whitelistCreate(params) {
    return this.submit("post", `${this.endpoint}/hotels/whitelists`, params);
  }

  whitelistDelete(supplierId) {
    return this.submit(
      "delete",
      `${this.endpoint}/hotels/whitelists/${supplierId}`
    );
  }

  blacklists(params) {
    this.parameters = params;

    return this.submit("get", `${this.endpoint}/hotels/blacklists`);
  }

  blacklistCreate(params) {
    return this.submit("post", `${this.endpoint}/hotels/blacklists`, params);
  }

  blacklistDelete(hotelBlacklistId) {
    return this.submit(
      "delete",
      `${this.endpoint}/hotels/blacklists/${hotelBlacklistId}`
    );
  }
}

export default AdminProxy;
