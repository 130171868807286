import InvoiceProxy from "../../../proxies/InvoiceProxy";

export const page = ({}, { invoiceId, onSuccess, onError }) => {
  new InvoiceProxy().find(invoiceId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new InvoiceProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const approve = ({}, { params, onSuccess, onError }) => {
  new InvoiceProxy().approve(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const reject = ({}, { invoiceId, onSuccess, onError }) => {
  new InvoiceProxy().reject(invoiceId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const revert = ({}, { invoiceId, onSuccess, onError }) => {
  new InvoiceProxy().revert(invoiceId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const debit = ({}, { params, onSuccess, onError }) => {
  new InvoiceProxy().debit(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const credit = ({}, { params, onSuccess, onError }) => {
  new InvoiceProxy().credit(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const regenerate = ({}, { params, onSuccess, onError }) => {
  new InvoiceProxy().regenerate(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  page,
  list,
  reject,
  approve,
  revert,
  debit,
  credit,
  regenerate,
};
