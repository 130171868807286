/* ============
 * Mutation types for the account module
 * ============
 *
 * The mutation types that are available
 * on the auth module.
 */

export const CHECK = "CHECK";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export default {
  CHECK,
  LOGIN,
  LOGOUT,
};
