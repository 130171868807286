import store from "@/store";
import PageProxy from "@/proxies/PageProxy";
import TransferProxy from "@/proxies/TransferProxy";
import ReservationProxy from "@/proxies/ReservationProxy";
import * as types from "./mutation-types";

export const page = ({ commit }, { onSuccess }) => {
  new PageProxy().transfers(store.state.route.query).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.SET_ERROR, error);
    }
  );
};

export const pageBook = ({}, { onSuccess, onError }) => {
  new PageProxy(store.state.route.query).transfersBook().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};

export const doFilter = ({ commit }, value) => {
  // Mutation
  commit(types.DO_FILTER, value);
};

export const doSearch = ({ commit }, value) => {
  // Mutation
  commit(types.DO_SEARCH, value);
};

export const filter = ({ commit }, { params, onSuccess }) => {
  // Make request
  new TransferProxy().search(params, false).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.transfers.doFilter
      ) {
        return;
      }
      // Success Callback
      commit(types.DO_FILTER, false);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_FILTER, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const search = ({ commit }, { params, onSuccess }) => {
  // Reset filters
  commit(types.SET_FILTERS_VALUES, {});
  // Make request
  new TransferProxy().search(params, true).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.transfers.doSearch
      ) {
        return;
      }
      // Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const book = ({}, { params, onSuccess, onError }) => {
  new ReservationProxy().book(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  page,
  pageBook,
  reset,
  doFilter,
  doSearch,
  filter,
  search,
  book,
};
