import AgencyProxy from "../../../proxies/AgencyProxy";
import SubAgencyProxy from "@/proxies/SubAgencyProxy";

export const create = ({}, { params, onSuccess, onError }) => {
  new AgencyProxy().create(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const get = ({}, { agencyId, onSuccess, onError }) => {
  new AgencyProxy().get(agencyId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const getCurrent = ({}, { onSuccess, onError }) => {
  new AgencyProxy().current().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new AgencyProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const listSubAgencies = ({}, { query, onSuccess, onError }) => {
  new SubAgencyProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const update = ({}, { agencyId, params, onSuccess, onError }) => {
  new AgencyProxy().update(agencyId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const approve = ({}, { params, onSuccess, onError }) => {
  new AgencyProxy().approve(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const updateCurrent = ({}, { agency, onSuccess, onError }) => {
  new AgencyProxy().currentUpdate(agency).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const deleteLogo = ({}, { token, fileName, onSuccess, onError }) => {
  new AgencyProxy({ token }).deleteLogo(fileName).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  create,
  get,
  getCurrent,
  update,
  updateCurrent,
  list,
  listSubAgencies,
  approve,
  deleteLogo,
};
