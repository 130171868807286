import { CURRENT, UPDATE, RESET } from "./mutation-types";

export default {
  [UPDATE](state, user) {
    state.name = user.name;
    state.email = user.email;
    state.clientCountryCode = user.clientCountryCode;
    state.clientCountryName = user.clientCountryName;
  },
  [CURRENT](state, user) {
    state.name = user.name;
    state.email = user.email;
    state.userId = user.userId;
    state.userRoleId = user.userRoleId;
    state.country = user.country;
    state.agencyId = user.agencyId;
    state.agencyName = user.agencyName;
    state.agencyType = user.agencyType;
    state.isTravelOperator = user.isTravelOperator;
    state.clientCountryCode = user.clientCountryCode;
    state.clientCountryName = user.clientCountryName;
  },
  [RESET](state) {
    state.name = null;
    state.email = null;
    state.userId = null;
    state.userRoleId = null;
    state.country = null;
    state.agencyId = null;
    state.agencyName = null;
    state.agencyType = null;
    state.isTravelOperator = null;
    state.clientCountryCode = null;
    state.clientCountryName = null;
  },
};
