import * as types from "./mutation-types";
import StatementProxy from "@/proxies/StatementProxy";

export const setLocale = ({ commit }, locale) => {
  localStorage.setItem("locale", locale);
  commit(types.SET_LOCALE, locale);
};

export const setCurrency = ({ commit }, currency) => {
  localStorage.setItem("currency", currency);
  commit(types.SET_CURRENCY, currency);
};

export const subagencyStatement = ({}, { id, onSuccess, onError }) => {
  new StatementProxy().get(id).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const subagencyStatements = ({}, { params, onSuccess, onError }) => {
  new StatementProxy().list(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const subagencyStatementOptions = ({}, { onSuccess, onError }) => {
  new StatementProxy().options().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const subagencyStatementCreate = (
  {},
  { params, onSuccess, onError }
) => {
  new StatementProxy().create(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const subagencyStatementUpdate = (
  {},
  { id, params, onSuccess, onError }
) => {
  new StatementProxy().update(id, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  setLocale,
  setCurrency,
  subagencyStatement,
  subagencyStatements,
  subagencyStatementOptions,
  subagencyStatementCreate,
  subagencyStatementUpdate,
};
