import * as enLocale from "vee-validate/dist/locale/en";
import * as bgLocale from "vee-validate/dist/locale/bg";
import enAttributes from "./attributes/attributes.en.json";
import bgAttributes from "./attributes/attributes.bg.json";

const dictionary = {
  en: {
    name: enLocale.name,
    messages: enLocale.messages,
    attributes: enAttributes,
  },
  bg: {
    name: bgLocale.name,
    messages: bgLocale.messages,
    attributes: bgAttributes,
  },
};

export default dictionary;
