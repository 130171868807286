import Proxy from "./Proxy";

class HotelProxy extends Proxy {
  /**
   * The constructor for the LocationProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("hotels", parameters);
  }

  /**
   *
   * @param id
   * @param params
   * @returns {Promise}
   */
  hotel(id, params) {
    return this.submit("post", `${this.endpoint}/${id}`, params);
  }

  /**
   * @param page
   * @param hotelId
   * @param searchId
   * @param filters
   * @returns {Promise}
   */
  rooms(page, hotelId, searchId, filters) {
    return this.submit("post", `${this.endpoint}/availability/rooms`, {
      page,
      hotelId,
      searchId,
      filters,
    });
  }

  /**
   * @param params
   * @param force
   * @returns {Promise}
   */
  search(params, force) {
    const data = Object.assign({}, params, { force: +force });
    return this.submit("post", `${this.endpoint}/availability`, data);
  }

  /**
   * @param params
   * @param force
   * @returns {Promise}
   */
  searchMap(params, force) {
    const data = Object.assign({}, params, { force: +force });
    return this.submit("post", `${this.endpoint}/availability/map`, data);
  }

  /**
   * @param id
   * @param params
   * @param force
   * @returns {Promise}
   */
  searchHotel(id, params, force) {
    const data = Object.assign({}, params, { force: +force });
    return this.submit("post", `${this.endpoint}/${id}/availability`, data);
  }

  /**
   * @param hotelId
   * @param searchId
   * @param offerId
   * @returns {Promise}
   */
  fees(hotelId, searchId, offerId) {
    return this.submit(
      "get",
      `${this.endpoint}/${hotelId}/availability/${searchId}/${offerId}/fees`
    );
  }

  /**
   * @param hotelId
   * @param searchId
   * @param offerId
   * @returns {Promise}
   */
  prepare(hotelId, searchId, offerId) {
    return this.submit(
      "get",
      `${this.endpoint}/${hotelId}/availability/${searchId}/${offerId}/prepare`
    );
  }
}

export default HotelProxy;
