/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import PageProxy from "@/proxies/PageProxy";
import * as types from "@/store/modules/notifications/mutation-types";

export const current = ({ commit }) => {
  new PageProxy().notifications().then(
    (response) => {
      // Success Callback
      commit(types.SET_CURRENT_NOTIFICATIONS, response || {});
    },
    () => {
      // Error Callback
      commit(types.SET_CURRENT_NOTIFICATIONS, {});
    }
  );
};

export default {
  current,
};
