import Proxy from "./Proxy";

class TranslateProxy extends Proxy {
  /**
   * The constructor for the TranslateProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("translate", parameters);
  }
}

export default TranslateProxy;
