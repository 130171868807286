/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import MessageProxy from "@/proxies/MessageProxy";
import * as types from "@/store/modules/messages/mutation-types";

export const view = ({}, { messageId, onSuccess, onError }) => {
  new MessageProxy().find(messageId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new MessageProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const send = ({}, { params, onSuccess, onError }) => {
  new MessageProxy().create(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const config = ({}, { query, onSuccess, onError }) => {
  new MessageProxy(query).config().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const current = ({ commit }) => {
  new MessageProxy().current().then(
    (response) => {
      // Success Callback
      commit(types.SET_CURRENT_MESSAGES, response.count || {});
    },
    () => {
      // Error Callback
      commit(types.SET_CURRENT_MESSAGES, {});
    }
  );
};

export const updateStatus = ({}, { messageId, status, onSuccess, onError }) => {
  new MessageProxy({ status }).updateStatus(messageId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  view,
  list,
  send,
  config,
  current,
  updateStatus,
};
