import { SET_LOCALE, SET_CURRENCY } from "./mutation-types";

export default {
  [SET_LOCALE](state, locale) {
    state.locale = locale;
  },
  [SET_CURRENCY](state, currency) {
    state.currency = currency;
  },
};
