/* ============
 * State of the Notifications module
 * ============
 *
 * The initial state of the notifications module.
 */

export default {
  creditUsed: 0,
  creditGiven: 0,
  creditLimit: 0,
  creditBalance: 0,
  messagesNew: 0,
  messagesAlert: 0,
  messagesAgency: 0,
  messagesHotel: 0,
  messagesSupplier: 0,
  reservationsUnpaid: 0,
  subAgenciesPayments: 0,
};
