import Proxy from "./Proxy";

class ActivityProxy extends Proxy {
  /**
   * The constructor for the ActivityProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("activities", parameters);
  }

  /**
   * @param params
   * @param force
   * @returns {Promise}
   */
  search(params, force) {
    const data = Object.assign({}, params, { force: +force });
    return this.submit("post", `${this.endpoint}/availability`, data);
  }

  /**
   * @param searchId
   * @param params
   * @returns {Promise}
   */
  searchResults(searchId, params) {
    return this.submit(
      "post",
      `${this.endpoint}/availability/${searchId}`,
      params
    );
  }

  /**
   * @param searchId
   * @param offerId
   * @returns {Promise}
   */
  details(searchId, offerId) {
    return this.submit(
      "get",
      `${this.endpoint}/availability/${searchId}/offer/${offerId}`
    );
  }

  /**
   * @param searchId
   * @param offerId
   * @param date
   * @param pickUpLocation
   * @returns {Promise}
   */
  options(searchId, offerId, date, pickUpLocation) {
    const params = {};
    if (pickUpLocation) {
      params.pickUpLocation = pickUpLocation;
    }
    return this.submit(
      "get",
      `${this.endpoint}/availability/${searchId}/offer/${offerId}/options/${date}`,
      { params }
    );
  }
}

export default ActivityProxy;
