/* ============
 * Mutations for the bookings module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from "./mutation-types";

export default {
  [types.SET_CURRENT_MESSAGES](state, count) {
    state.new = count.new || 0;
    state.alert = count.alert || 0;
  },
};
