/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file.
 */

export default [
  // Login
  {
    path: "/",
    name: "home.index",
    component: () => import("@/pages/home/index"),
    meta: {
      guest: true,
    },
  },

  // About us
  {
    path: "/about-us",
    name: "home.about-us",
    component: () => import("@/pages/home/about-us"),
    meta: {
      guest: true,
    },
  },

  // Contact us
  {
    path: "/contact-us",
    name: "home.contact-us",
    component: () => import("@/pages/home/contact-us"),
    meta: {
      guest: true,
    },
  },

  // Privacy policy
  {
    path: "/privacy-policy",
    name: "home.privacy-policy",
    component: () => import("@/pages/home/privacy-policy"),
    meta: {
      guest: true,
    },
  },

  // Terms & Conditions
  {
    path: "/terms-and-conditions",
    name: "home.terms-conditions",
    component: () => import("@/pages/home/terms-conditions"),
    meta: {
      guest: true,
    },
  },

  // Register
  {
    path: "/register",
    name: "home.register",
    component: () => import("@/pages/home/register"),
    meta: {
      guest: true,
    },
  },

  // Register Confirm
  {
    path: "/confirm",
    name: "home.confirm",
    component: () => import("@/pages/home/confirm"),
    meta: {
      guest: true,
    },
  },

  // Reset Password
  {
    path: "/reset",
    name: "home.reset",
    component: () => import("@/pages/home/reset"),
    meta: {
      guest: true,
    },
  },

  // Hotels
  {
    path: "/hotels",
    name: "hotels.index",
    component: () => import("@/pages/hotels/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/hotels/search",
    name: "hotels.search",
    component: () => import("@/pages/hotels/search"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/hotels/:id",
    name: "hotels.hotel",
    component: () => import("@/pages/hotels/hotel"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/hotels/:id/book",
    name: "hotels.book",
    component: () => import("@/pages/hotels/book"),
    meta: {
      auth: true,
    },
  },

  // Transfers
  {
    path: "/transfers",
    name: "transfers.index",
    component: () => import("@/pages/transfers/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transfers/search",
    name: "transfers.search",
    component: () => import("@/pages/transfers/search"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/transfers/book",
    name: "transfers.book",
    component: () => import("@/pages/transfers/book"),
    meta: {
      auth: true,
    },
  },

  // Activities
  {
    path: "/activities",
    name: "activities.index",
    component: () => import("@/pages/activities/index"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/activities/search",
    name: "activities.search",
    component: () => import("@/pages/activities/search"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/activities/book",
    name: "activities.book",
    component: () => import("@/pages/activities/book"),
    meta: {
      auth: true,
    },
  },

  // Payment
  {
    path: "/payments/:id",
    name: "payment.process",
    component: () => import("@/pages/settings/payment"),
    meta: {
      auth: true,
    },
  },

  // Agent settings
  {
    path: "/settings/agent",
    name: "settings.agent",
    component: () => import("@/pages/settings/agent"),
    meta: {
      auth: true,
    },
  },

  // Agency settings
  {
    path: "/settings/agency",
    name: "settings.agency",
    component: () => import("@/pages/settings/agency"),
    meta: {
      auth: true,
    },
  },

  // Bookings
  {
    path: "/settings/bookings",
    name: "settings.bookings",
    component: () => import("@/pages/settings/bookings"),
    meta: {
      auth: true,
    },
  },

  // Booking
  {
    path: "/settings/bookings/:reservationId/:reservationItemId",
    name: "settings.booking",
    component: () => import("@/pages/settings/booking"),
    meta: {
      auth: true,
    },
  },

  // Offers
  {
    path: "/settings/offers",
    name: "settings.offers",
    component: () => import("@/pages/settings/offers"),
    meta: {
      auth: true,
    },
  },

  // Offer Create
  {
    path: "/settings/offers/create",
    name: "settings.offerCreate",
    component: () => import("@/pages/settings/offer"),
    meta: {
      auth: true,
    },
  },

  // Offer
  {
    path: "/settings/offers/:offerId",
    name: "settings.offer",
    component: () => import("@/pages/settings/offer"),
    meta: {
      auth: true,
    },
  },

  // Invoices
  {
    path: "/settings/invoices",
    name: "settings.invoices",
    component: () => import("@/pages/settings/invoices"),
    meta: {
      auth: true,
    },
  },

  // Payments
  {
    path: "/settings/payments",
    name: "settings.payments",
    component: () => import("@/pages/settings/payments"),
    props: {
      recipient: "system",
    },
    meta: {
      auth: true,
    },
  },

  // Sub-Agencies
  {
    path: "/settings/sub-agencies",
    name: "settings.subAgenciesSettings",
    component: () => import("@/pages/settings/sub-agencies.vue"),
    meta: {
      auth: true,
    },
  },

  // Sub-Agency create
  {
    path: "/settings/sub-agencies/register",
    name: "settings.subAgencyRegister",
    component: () => import("@/pages/settings/sub-agency.vue"),
    meta: {
      auth: true,
    },
  },

  // Sub-Agency update
  {
    path: "/settings/sub-agencies/:id",
    name: "settings.subAgencyUpdate",
    component: () => import("@/pages/settings/sub-agency.vue"),
    meta: {
      auth: true,
    },
  },

  // Sub-agencies Payments
  {
    path: "/settings/sub-agencies-payments",
    name: "settings.subAgenciesPayments",
    component: () => import("@/pages/settings/payments"),
    props: {
      recipient: "operator",
    },
    meta: {
      auth: true,
    },
  },

  // Sub-Agencies Statements
  {
    path: "/settings/sub-agencies-statements",
    name: "settings.subAgenciesStatements",
    component: () => import("@/pages/settings/sub-agency-statements.vue"),
    meta: {
      auth: true,
    },
  },

  // Sub-Agency Statements create
  {
    path: "/settings/sub-agencies-statements/create",
    name: "settings.subAgenciesStatementCreate",
    component: () => import("@/pages/settings/sub-agency-statement.vue"),
    meta: {
      auth: true,
    },
  },

  // Sub-Agency Statements update
  {
    path: "/settings/sub-agencies-statements/:id",
    name: "settings.subAgenciesStatementUpdate",
    component: () => import("@/pages/settings/sub-agency-statement.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Index
  {
    path: "/admin",
    name: "admin.index",
    component: () => import("@/pages/admin/index"),
    meta: {
      auth: true,
    },
  },

  // Admin Agents
  {
    path: "/admin/agents",
    name: "admin.agents",
    component: () => import("@/pages/admin/agents"),
    meta: {
      auth: true,
    },
  },

  // Admin Agent
  {
    path: "/admin/agent/:id",
    name: "admin.agent",
    component: () => import("@/pages/admin/agent"),
    meta: {
      auth: true,
    },
  },

  // Admin Agencies
  {
    path: "/admin/agencies",
    name: "admin.agencies",
    component: () => import("@/pages/admin/agencies"),
    meta: {
      auth: true,
    },
  },

  // Admin Agency
  {
    path: "/admin/agencies/:id",
    name: "admin.agency",
    component: () => import("@/pages/admin/agency"),
    meta: {
      auth: true,
    },
  },

  // Admin Sub-Agencies
  {
    path: "/admin/sub-agencies",
    name: "admin.subAgencies",
    component: () => import("@/pages/admin/sub-agencies.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Sub-Agency
  {
    path: "/admin/sub-agencies/:id",
    name: "admin.subAgency",
    component: () => import("@/pages/admin/sub-agency.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Bookings
  {
    path: "/admin/bookings",
    name: "admin.bookings",
    component: () => import("@/pages/admin/bookings"),
    meta: {
      auth: true,
    },
  },

  // Booking
  {
    path: "/admin/bookings/:reservationId/:reservationItemId",
    name: "admin.booking",
    component: () => import("@/pages/admin/booking"),
    meta: {
      auth: true,
    },
  },

  // Admin Payments
  {
    path: "/admin/payments",
    name: "admin.payments",
    component: () => import("@/pages/admin/payments"),
    meta: {
      auth: true,
    },
  },

  // Payment
  {
    path: "/admin/payments/:id",
    name: "admin.payment",
    component: () => import("@/pages/admin/payment"),
    meta: {
      auth: true,
    },
  },

  // Admin Messages
  {
    path: "/admin/messages",
    name: "admin.messages",
    component: () => import("@/pages/admin/messages.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Commissions
  {
    path: "/admin/commissions",
    name: "admin.commissions",
    component: () => import("@/pages/admin/commissions"),
    meta: {
      auth: true,
    },
  },

  // Admin Commission
  {
    path: "/admin/commissions/:id",
    name: "admin.commission",
    component: () => import("@/pages/admin/commission"),
    meta: {
      auth: true,
    },
  },

  // Admin Confirmations
  {
    path: "/admin/confirmations",
    name: "admin.confirmations",
    component: () => import("@/pages/admin/confirmations"),
    meta: {
      auth: true,
    },
  },

  // Invoices
  {
    path: "/admin/invoices",
    name: "admin.invoices",
    component: () => import("@/pages/admin/invoices"),
    meta: {
      auth: true,
    },
  },

  // Invoice
  {
    path: "/admin/invoices/:id",
    name: "admin.invoice",
    component: () => import("@/pages/admin/invoice"),
    meta: {
      auth: true,
    },
  },

  // Admin Hotels Whitelist
  {
    path: "/admin/hotels/whitelist",
    name: "admin.hotels.whitelist",
    component: () => import("@/pages/admin/hotels/whitelist.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Hotels Blacklist
  {
    path: "/admin/hotels/blacklist",
    name: "admin.hotels.blacklist",
    component: () => import("@/pages/admin/hotels/blacklist.vue"),
    meta: {
      auth: true,
    },
  },

  // Admin Agencies Statements
  {
    path: "/admin/statements/agencies",
    name: "admin.statements.agencyStatements",
    component: () => import("@/pages/admin/statements/agencyStatements"),
    meta: {
      auth: true,
    },
  },

  // Admin Agencies Statement create
  {
    path: "/admin/locations/statements/agencies/create",
    name: "admin.statements.agencyStatementCreate",
    component: () => import("@/pages/admin/statements/agencyStatement"),
    meta: {
      auth: true,
    },
  },

  // Admin Agencies Statements update
  {
    path: "/admin/statements/agencies/:id",
    name: "admin.statements.agencyStatementsUpdate",
    component: () => import("@/pages/admin/statements/agencyStatement"),
    meta: {
      auth: true,
    },
  },

  // Admin Suppliers Statements
  {
    path: "/admin/statements/suppliers",
    name: "admin.statements.supplierStatements",
    component: () => import("@/pages/admin/statements/supplierStatements"),
    meta: {
      auth: true,
    },
  },

  // Admin Suppliers Statement create
  {
    path: "/admin/locations/statements/suppliers/create",
    name: "admin.statements.supplierStatementsCreate",
    component: () => import("@/pages/admin/statements/supplierStatement"),
    meta: {
      auth: true,
    },
  },

  // Admin Suppliers Statements update
  {
    path: "/admin/statements/suppliers/:id",
    name: "admin.statements.supplierStatementsUpdate",
    component: () => import("@/pages/admin/statements/supplierStatement"),
    meta: {
      auth: true,
    },
  },

  // Admin Hotel Searches
  {
    path: "/admin/searches/hotels",
    name: "admin.searches.hotels",
    component: () => import("@/pages/admin/searches/hotels"),
    meta: {
      auth: true,
    },
  },

  // Admin Transfer Searches
  {
    path: "/admin/searches/transfers",
    name: "admin.searches.transfers",
    component: () => import("@/pages/admin/searches/transfers"),
    meta: {
      auth: true,
    },
  },

  // Admin Activities Searches
  {
    path: "/admin/searches/activities",
    name: "admin.searches.activities",
    component: () => import("@/pages/admin/searches/activities"),
    meta: {
      auth: true,
    },
  },

  // Admin Hotel Requests
  {
    path: "/admin/requests/hotels",
    name: "admin.requests.hotels",
    component: () => import("@/pages/admin/requests/hotels"),
    meta: {
      auth: true,
    },
  },

  // Admin Transfer Requests
  {
    path: "/admin/requests/transfers",
    name: "admin.requests.transfers",
    component: () => import("@/pages/admin/requests/transfers"),
    meta: {
      auth: true,
    },
  },

  // Admin Transfer Requests
  {
    path: "/admin/requests/activities",
    name: "admin.requests.activities",
    component: () => import("@/pages/admin/requests/activities"),
    meta: {
      auth: true,
    },
  },

  // Admin Requests
  {
    path: "/admin/requests/payments",
    name: "admin.requests.payments",
    component: () => import("@/pages/admin/requests/payments"),
    meta: {
      auth: true,
    },
  },

  // Admin Statistics
  {
    path: "/admin/statistics",
    name: "admin.statistics",
    component: () => import("@/pages/admin/statistics"),
    meta: {
      auth: true,
    },
  },

  // Admin Log
  {
    path: "/admin/log",
    name: "admin.log",
    component: () => import("@/pages/admin/log"),
    meta: {
      auth: true,
    },
  },

  // Admin Airport
  {
    path: "/admin/locations/airports/:id",
    name: "admin.airport",
    component: () => import("@/pages/admin/locations/airport"),
    meta: {
      auth: true,
    },
  },

  // Admin Airports
  {
    path: "/admin/locations/airports",
    name: "admin.airports",
    component: () => import("@/pages/admin/locations/airports"),
    meta: {
      auth: true,
    },
  },

  // Admin Area
  {
    path: "/admin/locations/areas/create",
    name: "admin.areaCreate",
    component: () => import("@/pages/admin/locations/area"),
    meta: {
      auth: true,
    },
  },

  // Admin Area
  {
    path: "/admin/locations/areas/:id",
    name: "admin.areaUpdate",
    component: () => import("@/pages/admin/locations/area"),
    meta: {
      auth: true,
    },
  },

  // Admin Cities
  {
    path: "/admin/locations/areas",
    name: "admin.areas",
    component: () => import("@/pages/admin/locations/areas"),
    meta: {
      auth: true,
    },
  },

  // Admin City
  {
    path: "/admin/locations/cities/:id",
    name: "admin.city",
    component: () => import("@/pages/admin/locations/city"),
    meta: {
      auth: true,
    },
  },

  // Admin Cities
  {
    path: "/admin/locations/cities",
    name: "admin.cities",
    component: () => import("@/pages/admin/locations/cities"),
    meta: {
      auth: true,
    },
  },

  // Admin Destination
  {
    path: "/admin/locations/destinations/:id",
    name: "admin.destination",
    component: () => import("@/pages/admin/locations/destination"),
    meta: {
      auth: true,
    },
  },

  // Admin Destinations
  {
    path: "/admin/locations/destinations",
    name: "admin.destinations",
    component: () => import("@/pages/admin/locations/destinations"),
    meta: {
      auth: true,
    },
  },

  // Admin Meal
  {
    path: "/admin/configuration/meals/:id",
    name: "admin.meal",
    component: () => import("@/pages/admin/configuration/meal"),
    meta: {
      auth: true,
    },
  },

  // Admin Meals
  {
    path: "/admin/configuration/meals",
    name: "admin.meals",
    component: () => import("@/pages/admin/configuration/meals"),
    meta: {
      auth: true,
    },
  },

  // Admin Currencies
  {
    path: "/admin/configuration/currencies",
    name: "admin.currencies",
    component: () => import("@/pages/admin/configuration/currencies"),
    meta: {
      auth: true,
    },
  },

  // Redirect inactive routes to homepage
  {
    path: "/*",
    redirect: "/",
  },
];
