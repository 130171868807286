import Proxy from "./Proxy";

class StatementProxy extends Proxy {
  /**
   * The constructor for the PlaceProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("sub-agencies-statements", parameters);
  }

  /**
   * @returns {Promise}
   */
  get(id) {
    return this.submit("get", `${this.endpoint}/${id}`);
  }

  /**
   * @returns {Promise}
   */
  list(params) {
    this.parameters = params;
    return this.submit("get", `${this.endpoint}`);
  }

  /**
   * @returns {Promise}
   */
  options() {
    return this.submit("get", `${this.endpoint}/options`);
  }

  /**
   * @returns {Promise}
   */
  create(params) {
    return this.submit("post", `${this.endpoint}`, params);
  }

  /**
   * @returns {Promise}
   */
  update(id, params) {
    return this.submit("post", `${this.endpoint}/${id}`, params);
  }
}

export default StatementProxy;
