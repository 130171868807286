/* ============
 * Mutations for the bookings module
 * ============
 *
 * The mutations that are available on the
 * account module.
 */

import * as types from "./mutation-types";

export default {
  [types.SET_CURRENT_NOTIFICATIONS](state, count) {
    state.creditUsed = count.creditUsed || 0;
    state.creditGiven = count.creditGiven || 0;
    state.creditLimit = count.creditLimit || 0;
    state.creditBalance = count.creditBalance || 0;
    state.messagesNew = count.messagesNew || 0;
    state.messagesAlert = count.messagesAlert || 0;
    state.messagesAgency = count.messagesAgency || 0;
    state.messagesHotel = count.messagesHotel || 0;
    state.messagesSupplier = count.messagesSupplier || 0;
    state.reservationsUnpaid = count.reservationsUnpaid || 0;
    state.subAgenciesPayments = count.subAgenciesPayments || 0;
  },
};
