/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/routes";
import store from "@/store";

Vue.use(VueRouter);

export const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    if (
      (to.name === "hotels.search" && from.name === "hotels.search") ||
      (to.name === "hotels.hotel" && from.name === "hotels.hotel")
    ) {
      return false;
    }

    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  /*
   * Check localStorage session
   */
  store.dispatch("auth/check");

  if (to.matched.some((m) => m.meta.auth) && !store.state.auth.authenticated) {
    /*
     * If the user is not authenticated and visits
     * a page that requires authentication, redirect to the login page
     */
    next({
      name: "home.index",
    });
  } else {
    next();
  }
});

Vue.router = router;

export default {
  router,
};
