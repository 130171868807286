/* ============
 * State of the offer module
 * ============
 *
 * The initial state of the auth module.
 */

export default {
  count: 0,
  items: {},
};
