import Proxy from "./Proxy";

class AgencyProxy extends Proxy {
  /**
   * The constructor for the AgencyProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("sub-agencies", parameters);
  }

  /**
   * @returns {Promise}
   */
  get(agencyId) {
    return this.submit("get", `${this.endpoint}/${agencyId}`);
  }

  create(params) {
    return this.submit("post", `${this.endpoint}`, params);
  }

  /**
   * @returns {Promise}
   */
  update(agencyId, params) {
    return this.submit("post", `${this.endpoint}/${agencyId}`, params);
  }
}

export default AgencyProxy;
