/* ============
 * Main File
 * ============
 *
 * Will initialize the application.
 */

import Vue from "vue";

/* ============
 * Plugins
 * ============
 *
 * Import the plugins.
 */

import "./plugins/vuex";
import "./plugins/axios";
import "./plugins/vue-router";
import "./plugins/vuex-router-sync";
import "./plugins/font-awesome";
import "./plugins/normalize";
import "./plugins/lightbox";

/* ============
 * Main App
 * ============
 *
 * Last but not least, we import the main application.
 */

import App from "./app";

Vue.config.productionTip = process.env.NODE_ENV === "production";

new Vue({
  render: (h) => h(App),
}).$mount("#app");
