/* ============
 * Mutation types for the offer module
 * ============
 *
 * The mutation types that are available
 * on the offer module.
 */
export const SET_ITEMS = "SET_ITEMS";
export const SET_COUNT = "SET_COUNT";

export default {
  SET_ITEMS,
  SET_COUNT,
};
