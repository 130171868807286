import Proxy from "./Proxy";

class AuthProxy extends Proxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("auth", parameters);
  }

  /**
   * Method used to login.
   *
   * @param {String} username The username.
   * @param {String} password The password.
   *
   * @returns {Promise} The result in a promise.
   */
  login({ email, password }) {
    const data = {
      email,
      password,
    };

    return this.submit("post", `${this.endpoint}/login`, data);
  }

  /**
   * Method used to log out.
   *
   * @returns {Promise} The result in a promise.
   */
  logout() {
    return this.submit("post", `${this.endpoint}/logout`);
  }

  /**
   * Method used to register account.
   *
   * @param {Array} params Account data.
   *
   * @returns {Promise} The result in a promise.
   */
  register(params) {
    return this.submit("post", `${this.endpoint}/register`, params);
  }

  /**
   * Method used to send confirmation documents.
   *
   * @param {Array} params
   *
   * @returns {Promise} The result in a promise.
   */
  confirm(params) {
    return this.submit("post", `${this.endpoint}/confirm`, params);
  }

  /**
   * Method used to verify one-time password.
   *
   * @param {String} otp
   * @param {String} token
   *
   * @returns {Promise} The result in a promise.
   */
  otpVerify(otp, token) {
    return this.submit("post", `${this.endpoint}/otp/verify`, { otp, token });
  }

  /**
   * Method used to reset password.
   *
   * @param {String} email
   *
   * @returns {Promise} The result in a promise.
   */
  passwordReset(email) {
    return this.submit("post", `${this.endpoint}/password/reset`, { email });
  }

  /**
   * Method used to change password.
   *
   * @param {String} token
   * @param {String} password
   *
   * @returns {Promise} The result in a promise.
   */
  passwordChange(token, password) {
    return this.submit("post", `${this.endpoint}/password/change`, {
      token,
      password,
    });
  }
}

export default AuthProxy;
