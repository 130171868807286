import Proxy from "./Proxy";

class OfferProxy extends Proxy {
  /**
   * The constructor for the PlaceProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("offers", parameters);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  addOffer(params) {
    return this.submit("post", `${this.endpoint}/current`, params);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  removeOffer(params) {
    const offerId = params.offerId || "current";
    return this.submit("post", `${this.endpoint}/${offerId}/remove`, params);
  }

  /**
   * @returns {Promise}
   */
  createOffer(params) {
    const offerId = params.offerId || "current";
    return this.submit("post", `${this.endpoint}/${offerId}/create`, params);
  }
}

export default OfferProxy;
