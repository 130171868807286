import Proxy from "./Proxy";

class InvoiceProxy extends Proxy {
  /**
   * The constructor for the LocationProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("invoices", parameters);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  approve(params) {
    return this.submit(
      "post",
      `${this.endpoint}/${params.invoiceId}/approve`,
      params
    );
  }

  /**
   * @param params
   * @returns {Promise}
   */
  regenerate(params) {
    return this.submit(
      "post",
      `${this.endpoint}/${params.invoiceId}/regenerate`,
      params
    );
  }

  /**
   * @param id
   * @returns {Promise}
   */
  reject(id) {
    return this.submit("post", `${this.endpoint}/${id}/reject`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  revert(id) {
    return this.submit("post", `${this.endpoint}/${id}/revert`);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  debit(params) {
    return this.submit("post", `${this.endpoint}/${params.invoiceId}/debit`, {
      amount: params.amount,
    });
  }

  /**
   * @param params
   * @returns {Promise}
   */
  credit(params) {
    return this.submit("post", `${this.endpoint}/${params.invoiceId}/credit`, {
      amount: params.amount,
    });
  }
}

export default InvoiceProxy;
