import Proxy from "./Proxy";

class AgencyProxy extends Proxy {
  /**
   * The constructor for the AgencyProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("agencies", parameters);
  }

  /**
   * @returns {Promise}
   */
  get(agencyId) {
    return this.submit("get", `${this.endpoint}/${agencyId}`);
  }

  create(params) {
    return this.submit("post", `${this.endpoint}`, params);
  }

  /**
   * @returns {Promise}
   */
  update(agencyId, params) {
    return this.submit("post", `${this.endpoint}/${agencyId}`, params);
  }

  /**
   * @returns {Promise}
   */
  approve(params) {
    return this.submit(
      "post",
      `${this.endpoint}/${params.agencyId}/approve`,
      params
    );
  }

  /**
   * @returns {Promise}
   */
  current() {
    this.parameters = {
      lang: localStorage.getItem("locale"),
    };
    return this.submit("get", `${this.endpoint}/current`);
  }

  /**
   * @returns {Promise}
   */
  currentUpdate(agent) {
    return this.submit("post", `${this.endpoint}/current`, agent);
  }

  /**
   * @returns {Promise}
   */
  deleteLogo(fileName) {
    return this.submit("delete", `${this.endpoint}/logo/${fileName}`);
  }
}

export default AgencyProxy;
