import Proxy from "./Proxy";

class UserProxy extends Proxy {
  /**
   * The constructor for the ArtistProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("users", parameters);
  }

  /**
   * @returns {Promise}
   */
  get(userId) {
    return this.submit("get", `${this.endpoint}/${userId}`);
  }

  /**
   * @returns {Promise}
   */
  update(userId, params) {
    return this.submit("post", `${this.endpoint}/${userId}`, params);
  }

  /**
   * @returns {Promise}
   */
  current() {
    this.parameters = {
      lang: localStorage.getItem("locale"),
    };
    return this.submit("get", `${this.endpoint}/current`);
  }

  /**
   * @returns {Promise}
   */
  currentUpdate(params) {
    return this.submit("post", `${this.endpoint}/current`, params);
  }
}

export default UserProxy;
