import Proxy from "./Proxy";

class PaymentProxy extends Proxy {
  /**
   * The constructor for the PaymentProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("payments", parameters);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  create(params) {
    return this.submit("post", `${this.endpoint}`, params);
  }

  /**
   * @returns {Promise}
   */
  update(id, params) {
    return this.submit("post", `${this.endpoint}/${id}`, params);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  process(id) {
    return this.submit("post", `${this.endpoint}/${id}/process`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  approve(id) {
    return this.submit("post", `${this.endpoint}/${id}/approve`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  reject(id) {
    return this.submit("post", `${this.endpoint}/${id}/reject`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  cardInfo(id) {
    return this.submit("get", `${this.endpoint}/${id}/cardInfo`);
  }

  /**
   * @param id
   * @returns {Promise}
   */
  cardReturn(id) {
    return this.submit("post", `${this.endpoint}/${id}/cardReturn`);
  }

  /**
   * @param id
   * @param amount
   * @returns {Promise}
   */
  cardRefund(id, amount) {
    return this.submit("post", `${this.endpoint}/${id}/cardRefund`, { amount });
  }

  /**
   * @param id
   * @returns {Promise}
   */
  cardDeposit(id) {
    return this.submit("post", `${this.endpoint}/${id}/cardDeposit`);
  }

  /**
   * @returns {Promise}
   */
  options() {
    return this.submit("get", `${this.endpoint}/options`);
  }

  /**
   * @returns {Promise}
   */
  paymentList() {
    return this.submit("get", `${this.endpoint}`);
  }
}

export default PaymentProxy;
