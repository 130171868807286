/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import _ from "lodash";
import store from "@/store";
import PageProxy from "@/proxies/PageProxy";
import HotelProxy from "@/proxies/HotelProxy";
import ReservationProxy from "@/proxies/ReservationProxy";
import TranslateProxy from "@/proxies/TranslateProxy";

export const pageInfo = ({}, { onSuccess, onError }) => {
  new PageProxy(store.state.route.query)
    .hotel(store.state.route.params.id)
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Callback
        onError(error);
      }
    );
};

export const pageBook = ({}, { onSuccess, onError }) => {
  new PageProxy(store.state.route.query)
    .hotelBook(store.state.route.params.id)
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Callback
        onError(error);
      }
    );
};

export const search = ({}, { force, onSuccess, onError }) => {
  const params = _.extend({ force }, store.state.route.query);

  new HotelProxy(params).searchHotel(store.state.route.params.id).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const fees = (
  {},
  { hotelId, searchId, offerId, onSuccess, onError }
) => {
  new HotelProxy().fees(hotelId, searchId, offerId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const prepare = (
  {},
  { hotelId, searchId, offerId, onSuccess, onError }
) => {
  new HotelProxy().prepare(hotelId, searchId, offerId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const book = ({}, { params, onSuccess, onError }) => {
  new ReservationProxy().book(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const rooms = (
  {},
  { page, hotelId, searchId, filters, onSuccess, onError }
) => {
  new HotelProxy().rooms(page, hotelId, searchId, filters).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const translate = ({}, { params, onSuccess, onError }) => {
  new TranslateProxy().create(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  fees,
  book,
  rooms,
  search,
  prepare,
  pageInfo,
  pageBook,
  translate,
};
