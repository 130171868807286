import Proxy from "./Proxy";

class TransferProxy extends Proxy {
  /**
   * The constructor for the TransferProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("transfers", parameters);
  }

  /**
   * @param params
   * @param force
   * @returns {Promise}
   */
  search(params, force) {
    const data = Object.assign({}, params, { force: +force });
    return this.submit("post", `${this.endpoint}/availability`, data);
  }
}

export default TransferProxy;
