/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import store from "@/store";
import Vue from "vue";
import AuthProxy from "@/proxies/AuthProxy";
import * as types from "./mutation-types";

export const check = ({ commit }) => {
  commit(types.CHECK);
};

export const login = ({ commit }, { user, onOTPRequired, onError }) => {
  new AuthProxy().login(user).then(
    (response) => {
      if (!response.requireOTP) {
        // Mutation
        commit(types.LOGIN, response.token);
        // Dispatch event for getting user info
        store.dispatch("user/current", {
          onError: onError,
        });
        // Navigate to home
        Vue.router.push({
          name: "hotels.index",
        });
      } else {
        onOTPRequired(response.token);
      }
    },
    (error) => {
      onError(error);
    }
  );
};

export const logout = ({ commit }) => {
  new AuthProxy().logout().then(() => {
    // Mutation
    commit(types.LOGOUT);
    // Navigate to home
    Vue.router.push({
      name: "home.index",
    });
  });
};

export const register = ({}, { account, onError, onSuccess }) => {
  new AuthProxy().register(account).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      onError(error);
    }
  );
};

export const confirm = ({}, { params, onError, onSuccess }) => {
  new AuthProxy().confirm(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      onError(error);
    }
  );
};

export const otpVerify = ({ commit }, { otp, token, onError }) => {
  new AuthProxy().otpVerify(otp, token).then(
    () => {
      // Mutation
      commit(types.LOGIN, token);
      // Dispatch event for getting user info
      store.dispatch("user/current", {
        onError: onError,
      });
      // Navigate to home
      Vue.router.push({
        name: "hotels.index",
      });
    },
    (error) => {
      onError(error);
    }
  );
};

export const passwordReset = ({}, { email, onSuccess, onError }) => {
  new AuthProxy().passwordReset(email).then(
    () => {
      onSuccess();
    },
    (error) => {
      onError(error);
    }
  );
};

export const passwordChange = ({}, { token, password, onSuccess, onError }) => {
  new AuthProxy().passwordChange(token, password).then(
    () => {
      onSuccess();
    },
    (error) => {
      onError(error);
    }
  );
};

export default {
  check,
  login,
  logout,
  register,
  confirm,
  otpVerify,
  passwordReset,
  passwordChange,
};
