import PaymentProxy from "../../../proxies/PaymentProxy";

export const page = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().find(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const list = ({}, { query, onSuccess, onError }) => {
  new PaymentProxy(query).all().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const create = ({}, { params, onSuccess, onError }) => {
  new PaymentProxy().create(params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const update = ({}, { params, paymentId, onSuccess, onError }) => {
  new PaymentProxy().update(paymentId, params).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const approve = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().approve(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const reject = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().reject(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const process = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().process(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const options = ({}, { onSuccess }) => {
  new PaymentProxy().options().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    () => {
      // Error Callback
    }
  );
};

export const cardInfo = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().cardInfo(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const cardReturn = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().cardReturn(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const cardRefund = ({}, { amount, paymentId, onSuccess, onError }) => {
  new PaymentProxy().cardRefund(paymentId, amount).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const cardDeposit = ({}, { paymentId, onSuccess, onError }) => {
  new PaymentProxy().cardDeposit(paymentId).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export const paymentList = ({}, { params, onSuccess }) => {
  new PaymentProxy(params).paymentList().then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    () => {
      // Error Callback
    }
  );
};

export default {
  page,
  list,
  create,
  update,
  reject,
  approve,
  process,
  options,
  cardInfo,
  cardReturn,
  cardRefund,
  cardDeposit,
  paymentList,
};
