export default {
  colorpicker: {
    confirm: "OK",
    clear: "Clear",
  },
  datepicker: {
    now: "Now",
    today: "Today",
    cancel: "Cancel",
    clear: "Clear",
    confirm: "OK",
    selectDate: "Select date",
    selectTime: "Select time",
    startDate: "Start Date",
    startTime: "Start Time",
    endDate: "End Date",
    endTime: "End Time",
    prevYear: "Previous Year",
    nextYear: "Next Year",
    prevMonth: "Previous Month",
    nextMonth: "Next Month",
    year: "",
    month1: "January",
    month2: "February",
    month3: "March",
    month4: "April",
    month5: "May",
    month6: "June",
    month7: "July",
    month8: "August",
    month9: "September",
    month10: "October",
    month11: "November",
    month12: "December",
    week: "week",
    weeks: {
      sun: "Sun",
      mon: "Mon",
      tue: "Tue",
      wed: "Wed",
      thu: "Thu",
      fri: "Fri",
      sat: "Sat",
    },
    months: {
      jan: "Jan",
      feb: "Feb",
      mar: "Mar",
      apr: "Apr",
      may: "May",
      jun: "Jun",
      jul: "Jul",
      aug: "Aug",
      sep: "Sep",
      oct: "Oct",
      nov: "Nov",
      dec: "Dec",
    },
  },
  select: {
    loading: "Loading",
    noMatch: "No matching data",
    noData: "No data",
    placeholder: "Select",
  },
  cascader: {
    noMatch: "No matching data",
    loading: "Loading",
    placeholder: "Select",
    noData: "No data",
  },
  pagination: {
    goto: "Go to",
    pagesize: "/page",
    total: "Total {total}",
    pageClassifier: "",
  },
  messagebox: {
    title: "Message",
    confirm: "OK",
    cancel: "Cancel",
    error: "Illegal input",
  },
  upload: {
    deleteTip: "press delete to remove",
    delete: "Delete",
    preview: "Preview",
    continue: "Continue",
  },
  table: {
    emptyText: "No Data",
    confirmFilter: "Confirm",
    resetFilter: "Reset",
    clearFilter: "All",
    sumText: "Sum",
  },
  tree: {
    emptyText: "No Data",
  },
  transfer: {
    noMatch: "No matching data",
    noData: "No data",
    titles: ["List 1", "List 2"], // to be translated
    filterPlaceholder: "Enter keyword", // to be translated
    noCheckedFormat: "{total} items", // to be translated
    hasCheckedFormat: "{checked}/{total} checked", // to be translated
  },
  image: {
    error: "FAILED",
  },
  pageHeader: {
    title: "Back", // to be translated
  },
  popconfirm: {
    confirmButtonText: "Yes",
    cancelButtonText: "No",
  },
};
