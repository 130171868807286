export default {
  colorpicker: {
    confirm: "OK",
    clear: "Изчисти",
  },
  datepicker: {
    now: "Сега",
    today: "Днес",
    cancel: "Откажи",
    clear: "Изчисти",
    confirm: "ОК",
    selectDate: "Избери дата",
    selectTime: "Избери час",
    startDate: "Начална дата",
    startTime: "Начален час",
    endDate: "Крайна дата",
    endTime: "Краен час",
    prevYear: "Previous Year", // to be translated
    nextYear: "Next Year", // to be translated
    prevMonth: "Previous Month", // to be translated
    nextMonth: "Next Month", // to be translated
    year: "",
    month1: "Януари",
    month2: "Февруари",
    month3: "Март",
    month4: "Април",
    month5: "Май",
    month6: "Юни",
    month7: "Юли",
    month8: "Август",
    month9: "Септември",
    month10: "Октомври",
    month11: "Ноември",
    month12: "Декември",
    // week: 'Седмица',
    weeks: {
      sun: "Нед",
      mon: "Пон",
      tue: "Вто",
      wed: "Сря",
      thu: "Чет",
      fri: "Пет",
      sat: "Съб",
    },
    months: {
      jan: "Яну",
      feb: "Фев",
      mar: "Мар",
      apr: "Апр",
      may: "Май",
      jun: "Юни",
      jul: "Юли",
      aug: "Авг",
      sep: "Сеп",
      oct: "Окт",
      nov: "Ное",
      dec: "Дек",
    },
  },
  select: {
    loading: "Зареждане",
    noMatch: "Няма намерени",
    noData: "Няма данни",
    placeholder: "Избери",
  },
  cascader: {
    noMatch: "Няма намерени",
    loading: "Зареждане",
    placeholder: "Избери",
    noData: "Няма данни",
  },
  pagination: {
    goto: "Иди на",
    pagesize: "/страница",
    total: "Общо {total}",
    pageClassifier: "",
  },
  messagebox: {
    title: "Съобщение",
    confirm: "ОК",
    cancel: "Откажи",
    error: "Невалидни данни",
  },
  upload: {
    deleteTip: "press delete to remove", // to be translated
    delete: "Изтрий",
    preview: "Прегледай",
    continue: "Продължи",
  },
  table: {
    emptyText: "Няма данни",
    confirmFilter: "Потвърди",
    resetFilter: "Изчисти",
    clearFilter: "Всички",
    sumText: "Sum", // to be translated
  },
  tree: {
    emptyText: "Няма данни",
  },
  transfer: {
    noMatch: "Няма намерени",
    noData: "Няма данни",
    titles: ["List 1", "List 2"], // to be translated
    filterPlaceholder: "Enter keyword", // to be translated
    noCheckedFormat: "{total} items", // to be translated
    hasCheckedFormat: "{checked}/{total} checked", // to be translated
  },
  image: {
    error: "FAILED", // to be translated
  },
  pageHeader: {
    title: "Back", // to be translated
  },
  popconfirm: {
    confirmButtonText: "Yes", // to be translated
    cancelButtonText: "No", // to be translated
  },
};
