import Proxy from "./Proxy";

class MessageProxy extends Proxy {
  /**
   * The constructor for the MessageProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("messages", parameters);
  }

  /**
   * @returns {Promise}
   */
  config() {
    return this.submit("get", `${this.endpoint}/config`);
  }

  /**
   * @returns {Promise}
   */
  current() {
    return this.submit("get", `${this.endpoint}/current`);
  }

  /**
   * @returns {Promise}
   */
  updateStatus(id) {
    return this.submit("post", `${this.endpoint}/${id}/updateStatus`);
  }
}

export default MessageProxy;
