<template>
  <div id="app">
    <router-view v-if="isUserLoaded" :key="$route.path"></router-view>
  </div>
</template>
<script>
/* ============
 * Entry Point
 * ============
 *
 * The entry point of the application
 */
import { mapState } from "vuex";
import store from "./store";
import { router } from "./plugins/vue-router";
import { i18n } from "./plugins/vue-i18n";
import "./assets/scss/app.scss";
import { Message } from "element-ui";

export default {
  /**
   * The name of the application.
   */
  name: "vue-boilerplate",

  /**
   * The Vuex store.
   */
  store,

  /**
   * The router.
   */
  router,

  /**
   * The localization plugin.
   */
  i18n,

  created() {
    this.$i18n.locale = this.locale;
    this.$validator.localize(this.locale);
  },

  computed: {
    ...mapState({
      locale: (state) => state.settings.locale,
    }),
    isUserLoaded: () => {
      if (store.state.auth.authenticated) {
        return store.state.user.userId !== null;
      }

      return true;
    },
  },

  watch: {
    locale(newValue) {
      this.$i18n.locale = newValue;
      this.$validator.localize(this.locale);
    },
  },

  /**
   * Fires when the app has been mounted.
   */
  mounted() {
    // If the user is authenticated,
    // fetch the data from the API
    if (this.$store.state.auth.authenticated) {
      this.$store.dispatch("user/current", {
        onError: (error) => {
          Message.error(this.$t(`error.code_${error.code}`));
        },
      });
    }
  },
};
</script>
