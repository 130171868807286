/* ============
 * Actions for the auth module
 * ============
 *
 * The actions that are available on the
 * auth module.
 */

import store from "@/store";
import PageProxy from "@/proxies/PageProxy";
import HotelProxy from "@/proxies/HotelProxy";
import LocationProxy from "@/proxies/LocationProxy";
import * as types from "./mutation-types";

export const autocomplete = ({}, { search, requestId, onSuccess, onError }) => {
  new LocationProxy()
    .autocomplete({
      search: search,
      types: ["hotel"],
      requestId: requestId,
    })
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Callback
        onError(error);
      }
    );
};

export const page = ({ commit }, { onSuccess }) => {
  new PageProxy().hotels(store.state.route.query).then(
    (response) => {
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.SET_ERROR, error);
    }
  );
};

export const pageHotel = ({ commit }, { onSuccess }) => {
  new PageProxy(store.state.route.query)
    .hotel(store.state.route.params.id)
    .then(
      (response) => {
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Mutation
        commit(types.SET_ERROR, error);
      }
    );
};

export const reset = ({ commit }) => {
  commit(types.RESET);
};

export const doFilter = ({ commit }, value) => {
  // Mutation
  commit(types.DO_FILTER, value);
};

export const doSearch = ({ commit }, value) => {
  // Mutation
  commit(types.DO_SEARCH, value);
};

export const filter = ({ commit }, { params, onSuccess }) => {
  // Make request
  new HotelProxy().search(params, false).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doFilter
      ) {
        return;
      }
      // Success Callback
      commit(types.DO_FILTER, false);
      commit(types.SET_I18N, response.i18n);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_FILTER, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const filterHotel = ({ commit }, { params, onSuccess, onError }) => {
  // Make request
  new HotelProxy().searchHotel(store.state.route.params.id, params, false).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doFilter
      ) {
        return;
      }
      // Success Callback
      commit(types.DO_FILTER, false);
      commit(types.SET_I18N, response.i18n);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_FILTER, false);
      commit(types.SET_ERROR, error);
      if (onError) {
        onError(error);
      }
    }
  );
};

export const filterMap = ({ commit }, { params, onSuccess }) => {
  // Make request
  new HotelProxy().searchMap(params, false).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doFilter
      ) {
        return;
      }
      // Success Callback
      commit(types.DO_FILTER, false);
      commit(types.SET_I18N, response.i18n);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_FILTER, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const search = ({ commit }, { params, onSuccess }) => {
  // Reset filters
  commit(types.SET_FILTERS_VALUES, {});
  // Make request
  new HotelProxy().search(params, true).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doSearch
      ) {
        return;
      }
      // Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_I18N, response.i18n);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const searchHotel = ({ commit }, { params, onSuccess, onError }) => {
  // Reset filters
  commit(types.SET_FILTERS_VALUES, {});
  new HotelProxy()
    .searchHotel(store.state.route.params.id || params.hotelId, params, true)
    .then(
      (response) => {
        if (
          response.requestId &&
          response.requestId !== store.state.hotels.doSearch
        ) {
          return;
        }
        // Mutation
        commit(types.DO_SEARCH, false);
        commit(types.SET_I18N, response.i18n);
        commit(types.SET_RESULTS_TOTAL, response.total);
        commit(types.SET_RESULTS_COUNT, response.count);
        commit(types.SET_FILTERS_VALUES, response.filters);
        // Success Callback
        onSuccess(response);
      },
      (error) => {
        // Error Mutation
        commit(types.DO_SEARCH, false);
        commit(types.SET_ERROR, error);
        if (onError) {
          onError(error);
        }
      }
    );
};

export const searchMap = ({ commit }, { params, onSuccess }) => {
  // Reset filters
  commit(types.SET_FILTERS_VALUES, {});
  // Make request
  new HotelProxy().searchMap(params, true).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doSearch
      ) {
        return;
      }
      // Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_I18N, response.i18n);
      commit(types.SET_RESULTS_TOTAL, response.total);
      commit(types.SET_RESULTS_COUNT, response.count);
      commit(types.SET_FILTERS_VALUES, response.filters);
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Mutation
      commit(types.DO_SEARCH, false);
      commit(types.SET_ERROR, error);
    }
  );
};

export const searchMapHotel = ({}, { params, onSuccess, onError }) => {
  // Make request
  new HotelProxy().search(params, false).then(
    (response) => {
      if (
        response.requestId &&
        response.requestId !== store.state.hotels.doSearch
      ) {
        return;
      }
      // Success Callback
      onSuccess(response);
    },
    (error) => {
      // Error Callback
      onError(error);
    }
  );
};

export default {
  autocomplete,
  page,
  pageHotel,
  reset,
  doFilter,
  doSearch,
  filter,
  filterHotel,
  filterMap,
  search,
  searchHotel,
  searchMap,
  searchMapHotel,
};
