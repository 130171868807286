import Proxy from "./Proxy";

class LocationProxy extends Proxy {
  /**
   * The constructor for the LocationProxy.
   *
   * @param {Object} parameters The query parameters.
   */
  constructor(parameters = {}) {
    super("locations", parameters);
  }

  /**
   * @param params
   * @returns {Promise}
   */
  autocomplete(params) {
    this.parameters = {
      types: params.types,
      search: params.search,
      requestId: params.requestId,
    };

    return this.submit("get", `${this.endpoint}`);
  }
}

export default LocationProxy;
