/* ============
 * Mutations for the offer module
 * ============
 *
 * The mutations that are available on the
 * offer module.
 */

import * as types from "./mutation-types";

export default {
  [types.SET_ITEMS](state, value) {
    state.items = value || {};
  },
  [types.SET_COUNT](state, value) {
    state.count = value || 0;
  },
};
